<template>
<v-container>
  <br />
  <v-row>
    <br />

    <v-col cols="6" offset-md="3">
      <v-card>
        <v-card-title>Edit User</v-card-title>
        <v-col>
          <v-form>
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="userNumber"
                  label="Phone Number"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="userEmail"
                  label="Email"
                  outlined
                ></v-text-field>
                <v-btn @click="editUser()">Edit</v-btn>
              </v-form>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Admin',
  data() {
    return {
      firstName: null,
    lastName: null,
    userNumber: null,
    userEmail: null,
      userData: null,
    };
  },
  created() {
    axios
      .post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/user",
        {
          userId: this.$route.query.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            user_id: this.$store.getters.getUserId,
            token: this.$store.getters.getToken,
          },
        }
      )
      .then((res) => {
        this.firstName = res.data.data.first_name;
        this.lastName = res.data.data.last_name;
        this.userEmail = res.data.data.email;
        this.userNumber = res.data.data.number;
      });
  },
  methods: {
    editUser() {
      axios
        .post(
          "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/company/edit",
          {
            userId: this.$route.query.userId,
            userEmail: this.userEmail,
            firstName: this.firstName,
            lastName: this.lastName,
            userNumber: this.userNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              user_id: this.$store.getters.getUserId,
              token: this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.$router.push("/Admin");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
}
</script>
