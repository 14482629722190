<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="12">
      <v-card>
        <v-card-title>Calls</v-card-title>

        <v-col>
          <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">To</th>
                <th class="text-left">Timestamp</th>
                <th class="text-left">Recording</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in userData" :key="item.id">
                <td>
                  {{ item.outbound_number }}
                </td>
                <td>
                  {{ item.createdAt }}
                </td>
                <td>
                  <v-btn @click="openRecording(item.recording)" text
                    >Recording</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-col>
      </v-card>



    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Admin',
  data() {
    return {

      userData: null,
    };
  },
  created() {
    axios
        .post(
          "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/logs",
          {
            user: this.$route.query.userId,
          },
          {
            headers: {
              token: this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          this.userData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
  },
  methods: {
    openRecording(value) {
          axios
            .post(
              "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/recording",
              {
                sid: value,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  token: localStorage.getItem("Token"),
                },
              }
            )
            .then((res) => {
              console.log(res);
              window.open(
                "https://api.twilio.com/2010-04-01/Accounts/AC2bba5892c694f3ad954956db37b7fec5/Recordings/" +
                  res.data.sid +
                  ".mp3",
                "_blank"
              );
            })
            .catch((err) => {
              console.log(err);
            });
        },
  }
}
</script>
