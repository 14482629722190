import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';
import Call from '../views/Call.vue';
import Login from '../views/Login.vue';
import Messages from '../views/Messages.vue';
import Profile from '../views/Profile.vue';
import Conversation from '../views/Conversation.vue';
import Compose from '../views/Compose.vue'
import Recordings from '../views/Recordings.vue'
import EditEmail from '../views/UpdateEmail.vue'
import EditNumber from '../views/UpdateNumber.vue'
import Forgot from '../views/ForgotPassword.vue'
import Reset from '../views/ResetPassword.vue'
import Admin from '../views/Admin.vue'
import Edit from '../views/EditUser.vue'
import AdminCalls from '../views/adminCalls.vue'
import AdminMessages from '../views/adminMessages.vue'
import AdminConversation from '../views/adminConversation.vue'
import Video from '../views/Video.vue'
import CreateApp from '../views/createAppointment.vue'
import EditApp from '../views/editAppointment.vue'
import Appointments from '../views/appointments.vue'
import AdminAppointments from '../views/appointmentsAdmin.vue'


import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/call',
    name: 'Call',
    component: Call,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/video',
    name: 'Video',
    component: Video,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addApp',
    name: 'Create Appointment',
    component: CreateApp,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editApp',
    name: 'Edit Appointment',
    component: EditApp,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/appointments',
    name: 'Appointments',
    component: Appointments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/appointments-admin',
    name: 'Admin Appointments',
    component: AdminAppointments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editEmail',
    name: 'Edit Email',
    component: EditEmail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editNumber',
    name: 'Edit Number',
    component: EditNumber,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/recordings',
    name: 'Recordings',
    component: Recordings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/conversation',
    name: 'Conversation',
    component: Conversation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/compose',
    name: 'Compose',
    component: Compose,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Edit',
    name: 'Edit',
    component: Edit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/AdminCalls',
    name: 'Admin Calls',
    component: AdminCalls,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/AdminMessages',
    name: 'Admin Messages',
    component: AdminMessages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/AdminConversation',
    name: 'Admin Conversation',
    component: AdminConversation,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let state = store.state
    let isAuth = state.isAuth
    console.log('auth is', isAuth)
    if (isAuth == false) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router