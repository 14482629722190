<template>
<v-container>
  <v-row>
    <v-col cols="12" offset-md="1">
      <v-card-title>Home</v-card-title>
      <v-card-subtitle>{{name}} : <strong>{{twilioNumber}}</strong></v-card-subtitle>
    </v-col>
  </v-row>
  <br />
  <v-row>
    <v-col cols="5" offset-md="1">
      <v-card @click="navTo('call')" class="pb-10">
        <v-card-title class="text-subtitle-1">
          New Call
        </v-card-title>
        <br />
        <v-container>
          <v-row>
            <v-col md="5" offset-md="6">

              <v-img max-height="100" max-width="100" src="../assets/calls.svg"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="5">
      <v-card @click="navTo('compose')" class="pb-10">
        <v-card-title class="text-subtitle-1">
          New Message
        </v-card-title>
        <br />
        <v-container>
          <v-row>
            <v-col md="5" offset-md="6">

              <v-img max-height="100" max-width="100" src="../assets/new-messages.svg"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="5" offset-md="1">
      <v-card @click="navTo('recordings')" class="pb-10">
        <v-card-title class="text-subtitle-1">
          Recordings
        </v-card-title>
        <v-card-subtitle class="text-h5">{{recordings.length}}</v-card-subtitle>
        <v-container>
          <v-row>
            <v-col md="5" offset-md="6">

              <v-img max-height="100" max-width="100" src="../assets/recordings.svg"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="5">
      <v-card @click="navTo('messages')" class="pb-10">
        <v-card-title class="text-subtitle-1">
          Conversations
        </v-card-title>
        <v-card-subtitle class="text-h5">{{conversations.length}}</v-card-subtitle>
        <v-container>
          <v-row>
            <v-col md="5" offset-md="6">

              <v-img max-height="100" max-width="100" src="../assets/messages.svg"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      name: null,
      twilioNumber: null,
      recordings: [],
      conversations: [],

    };
  },
  created() {
    this.twilioNumber = this.$store.getters.getTwilioNumber
    this.name = this.$store.getters.getName
  },
  beforeMount() {
    axios
      .post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/logs", {
          user: this.$store.getters.getUserId,
        }, {
          headers: {
            token: this.$store.getters.getToken,
          },
        }
      )
      .then((response) => {

        this.recordings = response.data.data;
      });

    axios
      .post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/getConversations", {
          userId: this.$store.getters.getUserId,
        }, {
          headers: {
            token: this.$store.getters.getToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data)
        this.conversations = response.data.data;
      });
  },
  methods: {
    navTo(link) {
      this.$router.push(link)
    }
  }
}
</script>
