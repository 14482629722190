<template>
<v-container>
  <v-row align-content="center">
    <v-col cols="6" offset-md="3">
      <center>
        <v-img class="mt-15" contain max-height="150px" max-width="100px" src="../assets/logo.png"></v-img>
        <v-col class="mt-15" cols="8">
          <v-text-field v-model="password" required type="password" filled rounded label="password"></v-text-field>
          <v-text-field v-model="code" required type="text" filled rounded label="code"></v-text-field>
          <v-row>
            <v-col cols="12">
              <center>
                <v-btn @click="reset()" color="primary" rounded>Reset Password</v-btn>
              </center>
            </v-col>

          </v-row>
        </v-col>
      </center>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Reset',
  data() {
    return {
      code: null,
      password: null,
    };
  },
  methods: {
    reset() {

      axios
        .post("https://winsoc-call-auth-bza2l.ondigitalocean.app/api/user/reset", {
          token: this.code,
          password: this.password
        })
        .then((response) => {
          console.log(response)
          this.$router.push('/login')
        })
        .catch((error) => {
          console.log(error);
        });


    }
  }
}
</script>
