import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
      storage: localStorage,
      })
    ],
  state: {
    isAuth: false,
    userId: null,
    email: null,
    number: null,
    twilio_number: null,
    token: null,
    name: null,
    logo: 'https://discovr-analytics-data.fra1.digitaloceanspaces.com/Winsoc/logo.png',
    colour: '#2d6170',
    isAdmin: false,
    isSuperAdmin: false,
  },
  mutations: {
    login(state, userData) {
      state.isAuth = true
      state.userId = userData.id
      state.email = userData.email
      state.number = userData.number
      state.twilio_number = userData.twilio_number
      state.token = userData.token
      state.name = userData.first_name + " " +  userData.last_name
      state.logo = userData.white_label_logo
      state.colour = userData.white_label_colour
      state.isAdmin = userData.is_admin
      state.company = userData.company
      state.isSuperAdmin = userData.is_super_admin
    },
    logout(state) {
      state.isAuth = false
      state.userId = null
      state.email = null
      state.number = null
      state.twilio_number = null
      state.token = null
      state.name = null
      state.isAdmin = false
      state.isSuperAdmin = false
      state.company = null
      state.logo = 'https://discovr-analytics-data.fra1.digitaloceanspaces.com/Winsoc/logo.png'
      state.colour = '#2d6170'
    },
    updateEmail(state, newEmail) {
      state.email = newEmail
    },
    updateNumber(state, newNumber) {
      state.number = newNumber
    }
  },
  getters: {
    getEmail: state => state.email,
    getToken: state => state.token,
    getUserId: state => state.userId,
    getNumber: state => state.number,
    getTwilioNumber: state => state.twilio_number,
    getAuth: state => state.isAuth,
    getName: state => state.name,
    getColour: state => state.colour,
    getLogo: state => state.logo,
    getAdmin: state => state.isAdmin,
    getSuperAdmin: state => state.isSuperAdmin,
    getCompany: state => state.company,

  }

})
