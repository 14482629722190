<template>
<v-app id="inspire">

  <v-app-bar v-if="this.$store.getters.getAuth" app>
    <v-spacer></v-spacer>
    <v-btn v-show="this.$store.getters.getSuperAdmin" @click="navTo('/appointments-admin')" text>View Appointment</v-btn>
    <v-btn v-show="this.$store.getters.getSuperAdmin" @click="navTo('/addApp')" text>Add Appointment</v-btn>
    <v-btn v-show="this.$store.getters.getAdmin" @click="navTo('admin')" text>User Admin</v-btn>
    <v-btn  @click="navTo('profile')" text>Profile</v-btn>
  </v-app-bar>
  <v-navigation-drawer v-if="this.$store.getters.getAuth" app dark class="primary">
    <br />
    <center>
      <v-img conatin max-height="175px" max-width="100px" :src="logo"></v-img>
    </center>
    <br />
    <v-list>
      <v-list-item @click="navTo(link.path)" v-for="link in links" :key="link.name" link>
        <v-list-item-content>

          <v-list-item-title><strong>{{ link.name }}</strong></v-list-item-title>

        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-main>
    <router-view></router-view>
  </v-main>
</v-app>
</template>

<script>

import axios from 'axios';

export default {
  data: () => ({
    name: null,
    logo: null,
    colour: null,
    links: [{
        path: '/',
        name: 'Home',
      },
      {
        path: '/profile',
        name: 'Profile',
      },
      {
        path: '/recordings',
        name: 'Recordings',
      },
      {
        path: '/messages',
        name: 'Messages',
      },
      {
        path: '/appointments',
        name: 'Appointments',
      },
    ]
  }),
  created() {
    this.logo = this.$store.getters.getLogo
    this.colour = this.$store.getters.getColour

    axios
      .post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/logs", {
          user: this.$store.getters.getUserId,
        }, {
          headers: {
            token: this.$store.getters.getToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data)
      })
      .catch(err => {
        console.log(err)

        this.$store.commit("logout");
        this.$router.push("/login");
      })

  },
  updated() {
    this.name = this.$store.getters.getName
  },

  methods: {
    navTo(link) {
      this.$router.push(link)
    }
  }

}
</script>
