<template>
<v-container>
  <v-row align-content="center">
    <v-col cols="6" offset-md="3">
      <center>
        <v-img class="mt-15" contain max-height="150px" max-width="100px" src="../assets/logo.png"></v-img>
        <v-col class="mt-15" cols="8">
          <v-text-field v-model="email" required type="email" filled rounded label="email"></v-text-field>
          <v-text-field v-model="password" required type="password" filled rounded label="password"></v-text-field>
          <v-row>
            <v-col cols="6">
              <v-btn @click="login()" color="primary" rounded>Login</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="$router.push('/forgot')" color="secondary" rounded>Reset</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </center>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    login() {

      axios
        .post("https://winsoc-call-auth-bza2l.ondigitalocean.app/api/login", {
          email: this.email.toLowerCase(),
          password: this.password,
        })
        .then((response) => {
          this.$store.commit('login', response.data.data)
          console.log(response.data.data.is_super_admin)
          this.$router.push('/')
          location.reload(true);

        })
        .catch((error) => {
          console.log(error);
        });

      this.$router.push('/')
    }
  }
}
</script>
