<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="12">
      <v-card>
        <v-card-title>Appointments</v-card-title>
        <v-card-subtitle>All appointments</v-card-subtitle>
        <v-col>
          <thead>
        <tr class="pb-5">
          <th class="text-left pr-5"  width="15%">
            Time
          </th>
          <th class="text-left pr-5" width="50%">
            Notes
          </th>
          <th class="text-left pr-5">
            Name
          </th>
          <th class="text-left pr-5">
            Phone Number
          </th>
          <th class="text-center pr-5">
            Edit
          </th>
          <th class="text-center pr-5">
            Delete
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
        class="pb-5"
          v-for="item in appointments"
          :key="item.name"
        >
          <td class="text-left pr-5">{{ item.date }} - {{ item.time }}</td>
          <td class="text-left pr-5">{{ item.notes }}</td>
          <td class="text-left pr-5">{{ item.lead_name }}</td>
          <td class="text-left pr-5">{{ item.lead_number }}</td>
          <td class="text-left pr-5" @click="$router.push('/editApp?id='+item.id)"><v-btn text>Edit</v-btn></td>
          <td class="text-left pr-5"><v-btn @click="deleteApp(item.id)" text>Delete</v-btn></td>

        </tr>
      </tbody>

        </v-col>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>


<script>
import axios from 'axios';

export default {
  name: 'Appointments',
  data() {
    return {
      appointments: null,
    };
  },
  created() {

    axios.post('https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/getAllApp', {
      user_id: this.$store.getters.getUserId
    },
    {
      headers: {
        user_id: this.$store.getters.getUserId,
        token: this.$store.getters.getToken,
      },
    })
    .then(response => {
      this.appointments = response.data.sucess
    })
    .catch(err => {
      console.log(err)
    })

  },
  methods: {
    deleteApp(id){
      var r = confirm("Are you sure you want to delete this appointment?");
      if (r == true) {
      axios.post('https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/deleteApp', {
        id: id
      },
      {
        headers: {
          user_id: this.$store.getters.getUserId,
          token: this.$store.getters.getToken,
        },
      })
      .then(response => {
        console.log(response)
        location.reload(true);
      })
      .catch(err => {
        console.log(err)
      })
    }
    else {
      return
      }
    }
  }
}
</script>
