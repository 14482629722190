<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="12">
      <v-card>
        <v-card-title>Messages</v-card-title>

        <v-col>
          <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">To</th>
              <th class="text-left">Timestamp</th>
              <th class="text-left">Messages</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userData" :key="item.id">
              <td>
                {{ item.to }}
              </td>
              <td>
                {{ item.createdAt }}
              </td>
              <td>
                <v-btn @click="openMessages(uid, item.to)" text>Messages</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
        </v-col>
      </v-card>



    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Admin',
  data() {
    return {
      uid: this.$route.query.userId,
      userData: null,
    };
  },
  created() {
    axios
        .post(
          "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/getConversations",
          {
            userId: this.$route.query.userId,
          },
          {
            headers: {
              token: this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          this.userData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
  },
  methods: {
    openMessages(id, number) {
      var url = "/AdminConversation?userId=" + id + "&number=" + number;
      this.$router.push(url);
    },
  }
}
</script>
