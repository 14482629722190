<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="8" offset-md="2">
      <v-card>
        <v-card-title>Profile</v-card-title>
        <v-card-subtitle>{{name}}</v-card-subtitle>
        <v-col>
          <v-card-text class="text-center text-h6">Email: {{email}} </v-card-text>
          <v-card-text class="text-center text-h6">WinCall Number: {{twilioNumber}} </v-card-text>
          <v-card-text class="text-center text-h6">Linked Number: {{number}}</v-card-text>
          <br />
          <v-row class="pb-10">
            <v-col cols="4">
              <center>
                <v-btn @click="navTo('/editEmail')" rounded color="primary">Update Email</v-btn>
              </center>
            </v-col>
            <v-col cols="4">
              <center>
                <v-btn @click="navTo('/editNumber')" rounded color="primary">Update Linked Number</v-btn>
              </center>
            </v-col>
            <v-col cols="4">
              <center>
                <v-btn rounded color="error" @click="logout()">Logout</v-btn>
              </center>
            </v-col>
          </v-row>
        </v-col>

      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>


<script>
export default {
  name: 'Profile',
  data() {
    return {
      email: null,
      name: null,
      twilioNumber: null,
      number: null,
      links: [{
          path: '/',
          name: 'Home',
        },
        {
          path: '/profile',
          name: 'Profile',
        },
        {
          path: '/recordings',
          name: 'Recordings',
        },
        {
          path: '/messages',
          name: 'Messages',
        },
      ]
    };
  },
  created() {
    this.email = this.$store.getters.getEmail
    this.number = this.$store.getters.getNumber
    this.twilioNumber = this.$store.getters.getTwilioNumber
    this.name = this.$store.getters.getName


  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    navTo(link) {
      this.$router.push(link)
    }
  }
}
</script>
