<template>
<v-container>
  <v-btn>Create</v-btn>
</v-container>
</template>


<script>
export default {
  name: 'Video',
  data() {
    return {

    };
  },
  created() {


  },
}
</script>
