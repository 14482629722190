<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="8" offset-md="2">
      <v-card>
        <v-card-title>Recordings</v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Number
                </th>
                <th class="text-left">
                  Time
                </th>
                <th class="text-center">
                  Play
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in recordings" :key="item.id">
                <td>{{ item.outbound_number }}</td>
                <td>{{item.createdAt}}</td>
                <td class="text-center">
                  <v-btn color="primary" @click="playRecording(item.recording)" text>Play Recording</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      recordings: [],
      links: [{
          path: '/',
          name: 'Home',
        },
        {
          path: '/profile',
          name: 'Profile',
        },
        {
          path: '/recordings',
          name: 'Recordings',
        },
        {
          path: '/messages',
          name: 'Messages',
        },
      ]
    }
  },
  beforeMount() {
    axios
      .post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/logs", {
          user: this.$store.getters.getUserId,
        }, {
          headers: {
            token: this.$store.getters.getToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data)
        this.recordings = response.data.data;
      });
  },
  methods: {
    playRecording(item) {

      axios
        .post(
          "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/recording", {
            sid: item,
          }, {
            headers: {
              "Content-Type": "application/json",
              token: this.$store.getters.getToken,
            },
          }
        )
        .then((response) => {
          console.log(response)
          let recordingLink =
            "https://api.twilio.com/2010-04-01/Accounts/AC2bba5892c694f3ad954956db37b7fec5/Recordings/" +
            response.data.sid +
            ".mp3";
          window.open(recordingLink, "_blank")
        });
    },
  }
}
</script>
