import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import store from '../store'

Vue.use(Vuetify);

var colour = store.getters.getColour

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colour,
        secondary: '#49A7C9',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  }
});
