<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="6" offset-md="3">
      <v-card>
        <v-card-title>New Call</v-card-title>
        <v-card-subtitle>Placing a call will first initate the call by ringing your connected number then connecting you to the number you have called using your WinCall Number.</v-card-subtitle>
        <v-col>
          <v-text-field v-model="numberToCall" type="tel" label="Number to Call" outlined></v-text-field>
          <v-btn rounded color="primary" @click="call()">Place Call</v-btn>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      email: null,
      password: null,
      numberToCall: null,
    };
  },
  beforeMount() {
    if (this.$route.query.number != undefined) {
      this.numberToCall = this.$route.query.number
    }
  },
  methods: {
    call() {

      if (this.numberToCall.length >= 9) {
        axios
          .post(
            "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/makeCall", {
              outbound: this.$store.getters.getNumber,
              number: this.numberToCall,
              userId: this.$store.getters.getUserId,
              twilio_number: this.$store.getters.getTwilioNumber,
            }, {
              headers: {
                token: this.$store.getters.getToken,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.router.push("/home");
          });
      } else {
        this.presentAlert();
      }
    }
  }
}
</script>
