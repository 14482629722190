<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="8" offset-md="2">
      <v-card>
        <v-card-title>Messages</v-card-title>
        <v-card-subtitle>Click to read conversation</v-card-subtitle>
        <v-data-table
    :headers="headers"
    :items="conversations"
    item-key="to"
    :items-per-page="15"
    @click:row="rowClick" single-select
    class="elevation-1"
  ></v-data-table>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      selected: [],
      headers: [
        {
            text: 'To',
            align: 'start',
            value: 'to',
          },
          { text: 'Last Message Time', value: 'createdAt' },
      ],
      conversations: [],
      links: [{
          path: '/',
          name: 'Home',
        },
        {
          path: '/profile',
          name: 'Profile',
        },
        {
          path: '/recordings',
          name: 'Recordings',
        },
        {
          path: '/messages',
          name: 'Messages',
        },
      ]
    }
  },
  beforeMount() {
    axios
      .post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/getConversations", {
          userId: this.$store.getters.getUserId,
        }, {
          headers: {
            token: this.$store.getters.getToken,
          },
        }
      )
      .then((response) => {
        console.log(response.data.data)
        this.conversations = response.data.data;
      });
  },
  methods: {
    rowClick: function (item, row) {
      row.select(true);
      //item  - selected item
      this.$router.push("/conversation?number=" + item.to)
    }
  }
}
</script>
