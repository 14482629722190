<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="6" offset-md="3">
      <v-card>
        <v-card-title>Conversation with {{numberToMessage}}</v-card-title>
        <v-col>
          <v-card v-for="message in messages" :key="message.id">
            <v-card-text :class="'logTitle-' + message.type"><strong>{{message.message}}</strong></v-card-text>
            <v-card-text :class="'logSubtitle-' + message.type">{{message.createdAt }}</v-card-text>
          </v-card>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      numberToMessage: null,
      messageBody: null,
      messages: [],
    };
  },
  beforeMount() {


    if (this.$route.query.number != undefined) {
      this.numberToMessage = this.$route.query.number
    }


    axios
      .post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/getMessages", {
          userId: this.$route.query.userId,
          to_number: this.$route.query.number,
        }, {
          headers: {
            token: this.$store.getters.getToken,
          },
        }
      )
      .then((response) => {
        this.messages = response.data.data;
      });


  },
  methods: {

  }
}
</script>


<style>
.logTitle-sent {
  text-align: right;
  font-size: 14px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: -35px;
}

.logTitle-received {
  text-align: left;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: -35px;
}

.logSubtitle-sent {
  text-align: right;
  font-size: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.logSubtitle-received {
  text-align: left;
  font-size: 8px;
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
