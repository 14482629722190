<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="8">
      <v-card>
        <v-card-title>Users</v-card-title>

        <v-col>
          <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Calls</th>
              <th class="text-left">Messages</th>
              <th class="text-left">Edit</th>
              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userData" :key="item.id">
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>
                <v-btn @click="openCalls(item.id)" text>{{
                  item.calls
                }}</v-btn>
              </td>
              <td>
                <v-btn @click="openMessages(item.id)" text>{{
                  item.messages
                }}</v-btn>
              </td>
              <td>
                <v-btn @click="editUser(item.id)" text>Edit User</v-btn>
              </td>
              <td>
                <v-btn @click="deleteUser(item.id)" text color="error"
                  >Delete User</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
        </v-col>
      </v-card>
    </v-col>
    <v-col cols="4">
      <v-card>
        <v-card-title>Add User</v-card-title>
        <v-col>
          <v-form>
            <v-text-field
              v-model="firstName"
              label="First Name"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="lastName"
              label="Last Name"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="userNumber"
              label="Phone Number"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="userEmail"
              label="Email"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="userPassword"
              label="Password"
              outlined
            ></v-text-field>

            <v-btn @click="addUser()">Add</v-btn>
          </v-form>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Admin',
  data() {
    return {

      userData: null,
    };
  },
  created() {
    axios
      .post("https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/company/users",{company: this.$store.getters.getCompany} ,{
        headers: {
          user_id: this.$store.getters.getUserId,
          token: this.$store.getters.getToken,
        },
      })
      .then((res) => {
        this.userData = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    addUser() {
        axios
          .post(
            "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/user",
            [
              {
                email: this.userEmail,
                first_name: this.firstName,
                last_name: this.lastName,
                number: this.userNumber,
                company: this.$store.getters.getCompany,
                password: this.userPassword,
                white_label_logo: this.$store.getters.getLogo,
                white_label_colour: this.$store.getters.getColour
              },
            ],
            {
              headers: {
                "Content-Type": "application/json",
                user_id: this.$store.getters.getUserId,
                token: this.$store.getters.getToken,
              },
            }
          )
          .then((res) => {
            console.log(res);
            location.reload()
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editUser(userId) {
      var url = "/Edit?userId=" + userId;
      this.$router.push(url);
    },
    openCalls(userId) {
    var url = "/AdminCalls?userId=" + userId;
    this.$router.push(url);
  },
  openMessages(userId) {
    var url = "/AdminMessages?userId=" + userId;
    this.$router.push(url);
  },
      deleteUser(userId) {
      axios.post(
        "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/company/delete",
        {
          userId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            user_id: this.$store.getters.getUserId,
            token: this.$store.getters.getToken,
          },
        }
      ).then(location.reload())
    },
  }
}
</script>
