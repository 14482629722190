<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="6" offset-md="3">
      <v-card>
        <v-card-title>New Message</v-card-title>
        <v-col>
          <v-text-field v-model="numberToMessage" label="Number to Message" outlined></v-text-field>
          <v-textarea v-model="messageBody" label="Message" outlined></v-textarea>
          <v-btn rounded color="primary" @click="message">Send Message</v-btn>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      email: null,
      password: null,
      numberToMessage: null,
      messageBody: null,

    };
  },
  beforeMount() {
    if (this.$route.query.number != undefined) {
      this.numberToMessage = this.$route.query.number
    }
  },
  methods: {
    message() {

      if (this.numberToMessage.length >= 9) {
        axios
          .post(
            "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/sendMessage", {
              message: this.messageBody,
              to_number: this.numberToMessage,
              userId: this.$store.getters.getUserId,
              twilio_number: this.$store.getters.getTwilioNumber,
            }, {
              headers: {
                token: this.$store.getters.getToken,
              },
            }
          )
          .then((response) => {
            console.log(response);
            this.$router.push("/");
          });
      } else {
        this.presentAlert();
      }
    }
  }
}
</script>
