<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="10" offset-md="1">
      <v-card>
        <v-card-title>New Appointment</v-card-title>
        <v-card-subtitle>Creating an appointment will send a text with the details to the user selected.</v-card-subtitle>
        <v-col>

          <v-text-field label="Leads Name" outlined v-model="lead_name" ></v-text-field>
          <v-text-field label="Leads Phone Number" outlined v-model="lead_number"></v-text-field>
          <v-autocomplete label="User" v-model="user" :items="userData" item-text="email" item-value="id" outlined></v-autocomplete>
          <v-textarea label="Notes" outlined v-model="notes"></v-textarea>
          <v-row justify="center">
            <v-date-picker v-model="date" class="pr-5"></v-date-picker>
            <v-time-picker format="24hr" v-model="time" class="pl-5"></v-time-picker>
          </v-row>
          <v-btn :disabled="!disabled" rounded color="primary" @click="add()">Book Appointment</v-btn>
        </v-col>

      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Creat Appointment',
  data() {
    return {
      date: null,
      time: null,
      user: null,
      notes: null,
      userData: [],
      lead_name: null,
      lead_number: null,
    };
  },
  created() {
    axios
      .post("https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/getAllUsers",{} ,{
        headers: {
          user_id: this.$store.getters.getUserId,
          token: this.$store.getters.getToken,
        },
      })
      .then((res) => {
        this.userData = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });

  },
  computed: {
    disabled() {
      if (this.date != null && this.time != null && this.lead_name != null && this.lead_number != null && this.user != null && this.notes != null) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    add() {
      axios.post('https://winsoc-call-auth-bza2l.ondigitalocean.app/api/admin/addApp', {
        starts_at: this.date +'T'+ this.time + ':00',
        user: this.user,
        notes: this.notes,
        lead_name: this.lead_name,
        lead_number: this.lead_number,
        date: this.date,
        time: this.time,
      },
      {
        headers: {
          user_id: this.$store.getters.getUserId,
          token: this.$store.getters.getToken,
        },
      })
      .then(res => {
        console.log(res)
        this.$router.push('/')
      })
    }
  }
}
</script>
