<template>
<v-container>
  <br />
  <v-row>
    <br />
    <v-col cols="6" offset-md="3">
      <v-card>
        <v-card-title>Update Email</v-card-title>
        <v-col>
          <v-text-field v-model="email" type="email" label="New Email" outlined></v-text-field>
          <v-btn rounded color="primary" @click="update()">Update</v-btn>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Update Email',
  data() {
    return {
      email: null,

    };
  },
  methods: {
    update() {
      axios
        .post(
          "https://winsoc-call-auth-bza2l.ondigitalocean.app/api/user/email", {
            userId: this.$store.getters.getUserId,
            email: this.email.toLowerCase(),
          }, {
            headers: {
              token: this.$store.getters.getToken,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.$store.commit('updateEmail', this.email)
          this.email = null;
          this.$router.push("/profile");
        })
        .catch((error) => {
          console.log(error);
          this.presentAlert();
        });

    }
  }
}
</script>
